// LessonDetail.js

import React, { useState, useEffect } from 'react';
// import { Accordion, Card, Button, ListGroup } from 'react-bootstrap';
import { Link, Navigate, useParams } from 'react-router-dom';
// import React from 'react';
import { Accordion, Button, ListGroup } from 'react-bootstrap';
import { useLessonDetail, fetchLessonDetailApi } from 'utils/lessonDetailUtils';


// import SlowLevel from 'SlowLevel';
// import VerySlowLevel from 'VerySlowLevel';
// import NormalLevel from 'NormalLevel';

const LessonDetail = () => {
  const { lessonDetail, loading, error, setLessonId, fetchLesson } = useLessonDetail();
  const { lessonName } = useParams();

  // const [selectedLanguage, setSelectedLanguage] = useState('chinese');
  const [redirect, setRedirect] = useState(null);

  // const fetchLessonDetailData = async () => {
  //   try {
  //     // const response = await fetch(`https://docs.google.com/uc?export=open&id=1taZ9cSRw1IZRsGJroyy_n9wkXofA-Jqu`)
  //     const response = await fetch(`/data/${lessonName}.json`)
  //     if (response.ok) {
  //       const data = await response.json();

  //       setLesson(data);
  //     } else {
  //       console.error('Error fetching subtitle data:', response.status);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching subtitle data:', error);
  //   }
  // };

  useEffect(() => {
    fetchLesson(lessonName);
  }, [lessonName]);
  

  // const handleLanguageChange = (event) => {
  //   setSelectedLanguage(event.target.value);
  // };

  const handleTrackDetailsClick = (trackName, language, level, partName) => {
    // Replace spaces with underscores in the level parameter
    const sanitizedLevel = level.replace(/\s+/g, '_').toLowerCase();
    // Redirect to the language-specific track details with all necessary parameters
    setRedirect(`/play/${lessonName}/${trackName}/${language}/${sanitizedLevel.toLowerCase()}/${partName.toLowerCase()}`);
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }
  return (
    <div>
    <h2>{lessonDetail.title}</h2>
    <Accordion>
    {lessonDetail?.tracks?.sort((a, b) => a.name.localeCompare(b.name)).map((track, trackIndex) => (
      <Accordion.Item key={trackIndex} eventKey={trackIndex}>
        <div className="track">
          <div className="track-header">
            <Accordion.Header  variant="link" >
              {track.original_name}
            </Accordion.Header>
          </div>
          <Accordion.Body>

            <div className="track-content">
            <Accordion>
              {
              Object.keys(track.languages).map((lang_key) => (
                <Accordion.Item eventKey={`${track.name}_${lang_key}`} key={`${track.name}_${lang_key}`}>
                  <div key={`${track.name}_${lang_key}`} className="language">
                    <div className="language-header">
                      <Accordion.Header variant="link" >
                        {lang_key}
                      </Accordion.Header>
                    </div>
                    <Accordion.Body >
                      <div className="language-content">
                        <a href={track.full_text[lang_key]}>View Text</a>
                        {track.languages[lang_key].map((level, levelIndex) => (
                          <div key={levelIndex}>
                            <h5>{level.speed}</h5>
                            <ListGroup>
                              {level.parts.sort((a, b) => a.name.localeCompare(b.name)).map((part, partIndex) => (
                                <ListGroup.Item key={partIndex} onClick={() => handleTrackDetailsClick(track.name, lang_key, level.speed, part.name)}>
                                  {/* <Link
                                    to={`/lessons/${lessonId}/${track.id}/${selectedLanguage}/${sanitizedLevel.toLowerCase()}`}
                                    // onClick={() => handleTrackDetailsClick(trackIndex, level)}
                                  ></Link> */}
                                  {part.name}
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </div>
                        ))}

                        
                      </div>
                    </Accordion.Body>
                  </div>
                  </Accordion.Item>
                ))}
            </Accordion>
              
            </div>
          </Accordion.Body>
        </div>
      </Accordion.Item>
    ))}
     </Accordion>
  </div>
  );

 
};

export default LessonDetail;
