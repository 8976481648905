import React, { useState, useEffect } from 'react';
import { useAudioLabels,useAudioLanguage } from 'utils/audioUtils';
import { VALID_LABELS } from 'ducks/audioDuck';

const BadgeComponent = () => {

  const [maxWidth, setMaxWidth] = useState('40px');
  const {labelsState,  appendLabel,  removeLabel} = useAudioLabels()
  const audioLang = useAudioLanguage()
  const [labelList, setLabelList] = useState([])

  useEffect(() => {
      // Calculate the maximum width based on the longest label
      const langLabels = VALID_LABELS[audioLang];
          
      if (langLabels) {
        const maxLabelWidth = Math.max(...langLabels.map((label) => label.length));
        setMaxWidth(`${maxLabelWidth * 12 + 40}px`); // Adjust the multiplier as needed for a larger maxWidth
        setLabelList(langLabels);
      }
  }, [audioLang])


  const badgeStyle = {
    padding: '8px',
    margin: '2px', // Set a reduced margin
    border: '1px solid',
    borderRadius: '20px', // Set a more rounded border
    cursor: 'pointer',
    backgroundColor: 'white',
    color: 'black', // Set text color to black
    width: maxWidth,
    display: 'inline-block',
    textAlign: 'center',
    transition: 'background-color 0.3s, color 0.3s, font-weight 0.3s', // Add smooth transitions
  };

  const highlightedStyle = {
    width: maxWidth,
    backgroundColor: '#4caf50', // A green color for the highlighted background
    color: 'white', // White text color for contrast
    fontWeight: 'bold', // Make the text bolder
    borderRadius: '20px', // Set a more rounded border
  };

  const handleBadgeClick = (label) => {
    if(labelsState.indexOf(label) > -1){
      removeLabel(label)
    }
    else{
      appendLabel(label)
    }
  };

  return (
    <div>
      {labelList.map((label, index) => (
        <span
          key={index}
          onClick={() => handleBadgeClick(label)}
          style={{
            ...badgeStyle,
            ...(labelsState.indexOf(label) > -1? highlightedStyle : {}),
          }}
        >
          {label}
        </span>
      ))}
    </div>
  );
};

export default BadgeComponent;
