// LessonList.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';

const LessonList = () => {
  // Use state to manage the list of lessons
  const [lessons, setLessons] = useState([
    {
      id:1, title:'Day of the Death',
      name:"lesson_1"
    },
    {
      id:2, 
      title:'A Kiss',
      name:"lesson_2"
    },
    {
      id:3, title:'Bubba’s Food',
      name:"lesson_3"
    },
    {
      id:4, title:'Secret Love',
      name:"lesson_4"
    },
    {
      id:5, title:'Bad Choices',
      name:"lesson_5"
    },
    {
      id:6, title:'Double Standard',
      name:"lesson_6"
    },
 
    {
      id:8, title:'Longtime Affair',
      name:"lesson_8"
    },
    {
      id:9, title:'Lost Custody',
      name:"lesson_9"
    },
    {
      id:10, title:'Meddling Mother-In-Law',
      name:"lesson_10"
    },
    {
      id:11, title:'Nudist',
      name:"lesson_11"
    },
    {
      id:12, title:'Obsessive Behavior',
      name:"lesson_12"
    },
  ]);

  return (
    <Container>
      <Row>
        <Col>
          <h2>Lesson List</h2>
          <ListGroup>
            {lessons.map((lesson) => (
              <ListGroup.Item key={`lesson_${lesson.id}`}>
                {/* Link to LessonDetail with lesson id as a parameter */}
                <Link to={`/lessons/${lesson.name}`}>{lesson.title}</Link>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default LessonList;
