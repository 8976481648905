// transcriptDuck.js
// Redux Duck for managing transcript state

// Action types
const FETCH_TRANSCRIPT_REQUEST = 'transcript/FETCH_TRANSCRIPT_REQUEST';
const FETCH_TRANSCRIPT_SUCCESS = 'transcript/FETCH_TRANSCRIPT_SUCCESS';
const FETCH_TRANSCRIPT_FAILURE = 'transcript/FETCH_TRANSCRIPT_FAILURE';

// Initial state
const initialState = {
  transcripts: [],
  loading: false,
  error: null,
};

// Action creators
export const fetchTranscriptRequest = () => ({
  type: FETCH_TRANSCRIPT_REQUEST,
});

export const fetchTranscriptSuccess = (transcript) => ({
  type: FETCH_TRANSCRIPT_SUCCESS,
  payload: transcript,
});

export const fetchTranscriptFailure = (error) => ({
  type: FETCH_TRANSCRIPT_FAILURE,
  payload: error,
});

// Async action creator
export const fetchTranscript = (dispatch, transcriptUrl) => {
  return async (dispatch) => {
    dispatch(fetchTranscriptRequest());

    try {
      const response = await fetch(transcriptUrl);
      const transcriptData = await response.json();
      dispatch(fetchTranscriptSuccess(transcriptData));
    } catch (error) {
      dispatch(fetchTranscriptFailure(error.message));
    }
  };
};

// Reducer
const transcriptsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSCRIPT_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_TRANSCRIPT_SUCCESS:
      return { ...state, transcripts: action.payload, loading: false, error: null };

    case FETCH_TRANSCRIPT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default transcriptsReducer;
