import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useCurrentTime } from 'utils/audioUtils';
const CopyToClipboardButton = ({ contentText, start, end }) => {
  const currentTime = useCurrentTime()

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`explain words and pharse for sentence by english: ${contentText}`)
      .then(() => {
        setIsCopied(true);

        // Set a timeout to reset the notification after 2 seconds (adjust as needed)
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((error) => console.error('Error copying to clipboard:', error));
  };

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(`${contentText}`)
      .then(() => {
        setIsCopied(true);

        // Set a timeout to reset the notification after 2 seconds (adjust as needed)
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((error) => console.error('Error copying to clipboard:', error));
  };

  return (
    <div >
      {contentText&&
        <div >
          <span className={`${currentTime >= start && currentTime <= end ? 'focus-text3' : 'normal-text3'} pr-2`}>{contentText}</span>
          <FontAwesomeIcon icon={faCopy} onClick={copyTextToClipboard} style={{ cursor: 'pointer', color: 'blue',marginRight: '8px' }}/>
          <FontAwesomeIcon icon={faInfoCircle} onClick={copyToClipboard} style={{ cursor: 'pointer', color: 'green' }} />
        </div>
      }
      {isCopied && <p style={{ fontSize: '12px', color: 'green' }}>Copied!</p>}
    </div>
  );
};

export default CopyToClipboardButton;
