import { useParams } from 'react-router-dom';


export const useParamsPlayer = () => {
    const { lessonNameParam, trackNameParam, languageParam, speedLevelParam, partNameParam } = useParams();
    // Map keys to new keys without "Param" text and convert to lowercase
    const paramsMapped = Object.fromEntries(
        Object.entries({
        lessonNameParam,
        trackNameParam,
        languageParam,
        speedLevelParam,
        partNameParam,
        }).map(([key, value]) => [key.replace('Param', ''), value])
    );
    return paramsMapped
};

