export const SET_LESSONS = 'lesson/SET_LESSONS';
export const FETCH_LESSONS_REQUEST = 'lesson/FETCH_LESSONS_REQUEST';
export const FETCH_LESSONS_SUCCESS = 'lesson/FETCH_LESSONS_SUCCESS';
export const FETCH_LESSONS_FAILURE = 'lesson/FETCH_LESSONS_FAILURE';

export const setLessons = (lessons) => ({
  type: SET_LESSONS,
  payload: lessons,
});

export const fetchLessonsRequest = () => ({
  type: FETCH_LESSONS_REQUEST,
});

export const fetchLessonsSuccess = (lessons) => ({
  type: FETCH_LESSONS_SUCCESS,
  payload: lessons,
});

export const fetchLessonsFailure = (error) => ({
  type: FETCH_LESSONS_FAILURE,
  payload: error,
});

const initialState = {
    lessons: [],
    loading: false,
    error: null,
  };
  
  const lessonsReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_LESSONS:
        return { ...state, lessons: action.payload };
  
      case FETCH_LESSONS_REQUEST:
        return { ...state, loading: true, error: null };
  
      case FETCH_LESSONS_SUCCESS:
        return { ...state, lessons: action.payload, loading: false, error: null };
  
      case FETCH_LESSONS_FAILURE:
        return { ...state, loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  
  export default lessonsReducer;
  