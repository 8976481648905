import React, { useState, useRef, useEffect } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import ControlComponent from 'ControlComponent';
import CopyToClipboardButton from 'CopyToClipboardButton'
import { useSetAudioUrl, useCurrentTime, useFocusItem, useAudioLabels,
  useSetFocusItem,useSetLanguage,useSetSpeed } from 'utils/audioUtils';
import { useLessonDetail } from 'utils/lessonDetailUtils';
import { useParamsPlayer } from 'utils/common';
import { useTranscripts } from 'utils/transcriptsUtils';
import { VALID_LABELS } from 'ducks/audioDuck';

import 'AudioTranscriptPlayer.css';
const AudioTranscriptPlayer = () => {
  const {labelsState, appendLabel} = useAudioLabels()

  const { lessonDetail, fetchLesson } = useLessonDetail();
  const focusItem = useFocusItem();
  const setFocusItem = useSetFocusItem();
  const { transcripts, fetchTranscriptData } = useTranscripts();

  const { lessonName, trackName, language, speedLevel, partName } = useParamsPlayer();
  const setLanguage = useSetLanguage()
  const setSpeed = useSetSpeed()

  const setAudioURLStore = useSetAudioUrl()
  const currentTime = useCurrentTime()

  const subtitlesRef = useRef(null);

  useEffect(() => {
    fetchLesson(lessonName);
  }, [lessonName]);



  useEffect( () => {
    if(!lessonDetail.name) return;
    
    const track = lessonDetail?.tracks?.find(item => item.name === trackName);
    const level = track?.languages[language.toLocaleLowerCase()]?.find(item => item.speed === speedLevel);

    const part = level?.parts.find(item => item.name === partName);
    setAudioURLStore(part?.audioUrl)
    
    setLanguage(language)
    setSpeed(speedLevel)
    fetchTranscriptData(part?.transcriptUrl);
    const langLabels = VALID_LABELS[language]
    appendLabel(langLabels)

  }, [lessonDetail, trackName, language, speedLevel, partName]);


  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);
    return `${minutes}:${sec < 10 ? '0' : ''}${sec}`;
  };


  useEffect(() => {
    scrollToSubtitle();
  }, [currentTime]);

  const scrollToSubtitle = () => {
    const subtitle = transcripts?.find((sub) => currentTime >= sub.start && currentTime <= sub.end);
    if (subtitle && subtitlesRef.current && subtitlesRef.current.scrollHeight > 200) {
      const startTime = subtitle.start
      const element = document.getElementById(`subtitle-${startTime}`);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const selectSection = (subtitle) => {
    if (focusItem && focusItem.itemId === subtitle.id) {
      // Deselect the section if already selected
      setFocusItem(0, 0, 0);
    } else {
      setFocusItem(subtitle.id, subtitle.start, subtitle.end);
    }
  };

  const getSubtitleText=(subtitle)=> {

    switch(language){
      case 'chinese':
        return (
          <div style={{ flex: 1 }}>
              {labelsState.indexOf('en') >-1 &&<div className={`${currentTime >= subtitle.start && currentTime <= subtitle.end ? 'focus-text1': 'normal-text1'}`}>{subtitle.en}</div>}
              <CopyToClipboardButton contentText={subtitle.zh} start={subtitle.start} end={subtitle.end}/>
              {labelsState.indexOf('py') >-1 &&<div className={`${currentTime >= subtitle.start && currentTime <= subtitle.end ? 'focus-text2': 'normal-text2'}`}>{subtitle.py}</div>}
          </div>
        )
      case 'japanese':
        return (
          <div style={{ flex: 1 }}>
              {labelsState.indexOf('en') >-1&&<div className={`${currentTime >= subtitle.start && currentTime <= subtitle.end ? 'focus-text1': 'normal-text1'}`}>{subtitle.en}</div>}
              <CopyToClipboardButton contentText={subtitle.jp} start={subtitle.start} end={subtitle.end}/>
              {labelsState.indexOf('romaji') >-1 &&<div className={`${currentTime >= subtitle.start && currentTime <= subtitle.end ? 'focus-text2': 'normal-text2'}`}>{subtitle.romaji}</div>}

          </div>
        )
      case 'spanish':
        return (
          <div style={{ flex: 1 }}>
              {labelsState.indexOf('en') >-1&&<div className={`${currentTime >= subtitle.start && currentTime <= subtitle.end ? 'focus-text1': 'normal-text1'}`}>{subtitle.en}</div>}
              <CopyToClipboardButton contentText={subtitle.es} start={subtitle.start} end={subtitle.end}/>
          </div>
        )
      case 'germany':
        return (
          <div style={{ flex: 1 }}>
              {labelsState.indexOf('en') >-1&&<div className={`${currentTime >= subtitle.start && currentTime <= subtitle.end ? 'focus-text1': 'normal-text1'}`}>{subtitle.en}</div>}
              <CopyToClipboardButton contentText={subtitle.de} start={subtitle.start} end={subtitle.end}/>
          </div>
        )
      case 'dutch':
        return (
          <div style={{ flex: 1 }}>
              {labelsState.indexOf('en') >-1&&<div className={`${currentTime >= subtitle.start && currentTime <= subtitle.end ? 'focus-text1': 'normal-text1'}`}>{subtitle.en}</div>}
              <CopyToClipboardButton contentText={subtitle.nl} start={subtitle.start} end={subtitle.end}/>

          </div>
        )
    }

   
  }


  return (
    <div className="full-screen-container">
      <div className='transcripts-container'>
        <Container>
          <div className="subtitle-display mt-3" ref={subtitlesRef} style={{ flex: 1, overflow: 'auto' }}>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {transcripts &&
                transcripts.map((subtitle, index) => {
                    return(
                      <li
                        key={index}
                        id={`subtitle-${subtitle.start}`}
                        style={{
                          marginBottom: '20px',
                          padding: '10px',
                          border: '1px solid #ccc',
                          borderRadius: '5px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        className={`${currentTime >= subtitle.start && currentTime <= subtitle.end ? 'focus-background': ''}`}
                      >
                        
                        {getSubtitleText(subtitle)}
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <div style={{ width: '100%', cursor: 'pointer', color: '#2ecc71' }}>
                            {formatTime(subtitle.start)}
                          </div>
                          {subtitle.en &&
                            <div style={{ textAlign: 'center', width: '100%' }}>
                              <div style={{ display: 'inline-block', cursor: 'pointer', color: subtitle.id === focusItem.itemId ? '#3498db' : '#ccc', margin: 'auto' }} onClick={() => selectSection(subtitle)}>
                                <FontAwesomeIcon icon={faUndo} size="lg" />
                              </div>
                            </div>                          
                          }
                        </div>
                      </li>
                    )
                }
                
              )}
            </ul>
          </div>
        </Container>
      </div>
      <div className='controller-container'>
        <Container>
          <ControlComponent />
        </Container>
      </div>
    </div>  
  );
};

export default AudioTranscriptPlayer;
