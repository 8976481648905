import React, { useState , useRef, useEffect} from 'react';
import { Container, Col, Button, ButtonGroup, Dropdown, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faArrowsRotate,faBars, faPlaneCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import BadgeComponent from 'BadgeComponent'
import { useAudioUrl,useUpdateCurrentTime,useFocusItem,useSetLanguage, useSetSpeed, useAudioSpeed, useAudioLanguage,useAudioRepeat,useSetFocusItem } from 'utils/audioUtils';
import { useParamsPlayer } from 'utils/common';
import { useLessonDetail } from 'utils/lessonDetailUtils';
import AudioPlayer from 'AudioPlayer';


const ControlComponent = () => {
    const navigate = useNavigate();
    const {repeatState, setRepeat} = useAudioRepeat()
    const focusItem = useFocusItem();

    const setLanguage = useSetLanguage()
    const setSpeed = useSetSpeed()
    const audioSpeed = useAudioSpeed()
    const audioLang = useAudioLanguage()
    const [prevAudioLang, setPrevAudioLang] = useState(audioLang);
    const [prevAudioSpeed, setPrevAudioSpeed] = useState(audioSpeed);
    const setFocusItem = useSetFocusItem();

    // Assign each key to a new constant
    const { lessonName, trackName, language, speedLevel, partName } = useParamsPlayer();
    const { lessonDetail } = useLessonDetail();
  useEffect(() => {
      const track = lessonDetail?.tracks?.find(item => item.name === trackName);
      if(track){
        setLangList(Object.keys(track.languages))
        setSpeedList(track?.languages[language].map(x => x.speed) )
      }
  }, [lessonName, trackName, language, speedLevel, partName]);

  useEffect(() => {
    
    if(audioLang !== prevAudioLang || audioSpeed !== prevAudioSpeed){
      setPrevAudioLang(audioLang)
      setPrevAudioSpeed(audioSpeed)
      const newUrl = `/play/${lessonName}/${trackName}/${audioLang}/${audioSpeed}/${partName.toLowerCase()}`
      navigate(newUrl)
    }
  }, [audioLang, audioSpeed])




  const [isOpen, setIsOpen] = useState(false);
  
  const [speedList, setSpeedList] = useState([]);
  const [langList, setLangList] = useState([]);


  const handleRepeatToggle = () => {
    setRepeat(!repeatState); // Toggle repeat state
  };


  


  

  const handleSpeedChange = (selectedSpeed) => {
    if(selectedSpeed) setSpeed(selectedSpeed);
  };

  const handleLanguageChange = (selectedLanguage) => {
    if(selectedLanguage) setLanguage(selectedLanguage);
  };
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };


  const clearFocus = () => {
      setFocusItem(0, 0, 0);
  };
  return (
    <div>
        {isOpen && (
            <div style={{ textAlign: 'left'}}>
                <Row  >
                    <Col  xs={12} md={8} className=" d-flex mb-2 mt-2 align-items-center">
                        <Row>
                            <Col xs={12} md={12} >

                                <ButtonGroup className="d-flex flex-column flex-md-row mb-2">
                                  
                                  <a href="/">
                                    <Button         
                                      variant={'secondary'}
                                      onClick={handleRepeatToggle}
                                      > 
                                        <FontAwesomeIcon icon={faBars} />

                                    </Button>
                                  </a>
                                <Dropdown as={ButtonGroup} className="mb-2 mb-md-0">
                                    <Dropdown.Toggle variant="info" id="speed-dropdown" style={{ backgroundColor: '#ccebff', minWidth: '140px' }}>
                                    Speed: {audioSpeed}
                                    </Dropdown.Toggle>
                    
                                    <Dropdown.Menu>
                                    {speedList && speedList.map( x => {
                                      return(<Dropdown.Item key={x}  active={x === audioSpeed} onClick={() => handleSpeedChange(x)}>{x}</Dropdown.Item>)
                                    })}
                                    </Dropdown.Menu>
                                </Dropdown>
                    
                                <Dropdown as={ButtonGroup} className="mb-2 mb-md-0">
                                    <Dropdown.Toggle variant="info" id="language-dropdown" style={{ backgroundColor: '#d3f1d3', minWidth: '140px' }}>
                                    Language: {language}
                                    </Dropdown.Toggle>
                    
                                    <Dropdown.Menu>
                                    {langList && langList.map( x => {
                                      return(
                                        <Dropdown.Item key={x} active={x === language} onClick={() => handleLanguageChange(x)}>{x}</Dropdown.Item>
                                      )
                                    })}

                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button         
                                  variant={repeatState ? 'primary' : 'secondary'}
                                  onClick={handleRepeatToggle}
                                  > 
                                  <FontAwesomeIcon icon={faArrowsRotate} />

                                </Button>
                    
                                </ButtonGroup>
                            
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} md={4} className="d-flex mb-2 mt-2 justify-content-end">
                        <Button         
                          variant={focusItem.itemId && focusItem.itemId !== 0 ? 'primary' : 'secondary'}
                          onClick={clearFocus}
                          > 
                          <FontAwesomeIcon icon={faPlaneCircleExclamation} />

                        </Button>
                        <BadgeComponent
                        />
                    </Col>
                </Row>
            </div>
        )}
        <div>
        <Row className="align-items-center">
            <Col xs={10} md={11} className="d-flex justify-content-center">
                <AudioPlayer/>
            </Col>
            <Col xs={2} md={1} className="d-flex justify-content-center">
                <button onClick={toggleCollapse} className="btn btn-primary">
                {isOpen ? (
                    <FontAwesomeIcon icon={faChevronDown} />
                    ) : (
                    <FontAwesomeIcon icon={ faChevronUp} />
                    )}
                </button>
            </Col>
            
        </Row>
        </div>
      
    </div>
  );
};

export default ControlComponent;
