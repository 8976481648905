// audioUtils.js
// Utility functions related to audio management

import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTime, setAudioUrl, setFocus, setLanguage, setSpeed, setRepeatState, appendLabel, removeLabel} from 'ducks/audioDuck';
import { useParamsPlayer } from 'utils/common';
// Selectors
export const useCurrentTime = () => useSelector((state) => state.audio.currentTime);
export const useAudioLanguage = () => useSelector((state) => state.audio.language);
export const useAudioSpeed = () => useSelector((state) => state.audio.speed);
export const useAudioUrl = () => useSelector((state) => state.audio.audioUrl);
export const useFocusItem = () => useSelector((state) => state.audio.focusItem);

const SheetEndpoint = "https://script.google.com/macros/s/AKfycbyTsk_ZQ-kPLXvQxVFcCy18AODr1wt8SeaORBvaq23ZoDDx03xsS_55_Mc4fQ0pIcm7/exec"
// Operations
export const useSetAudioUrl = () => {
  const dispatch = useDispatch();
  return (url) => {
    dispatch(setAudioUrl(url));
  };
};


export const useUpdateCurrentTime = () => {
    const dispatch = useDispatch();
    return (time) => {
      dispatch(setCurrentTime(time));
    };
};

// Custom hook for setting focusItem
export const useSetFocusItem = () => {
    const dispatch = useDispatch();
    return (itemId, start, end) => {
      dispatch(setFocus(itemId, start, end));
    };
};

// Custom hook for setting language
export const useSetLanguage = () => {
  const dispatch = useDispatch();
  return (language) => {
    dispatch(setLanguage(language));
  };
};

// Custom hook for setting speed
export const useSetSpeed = () => {
  const dispatch = useDispatch();
  return (speed) => {
    dispatch(setSpeed(speed));
  };
};

// Custom hook for accessing language and speed from the Redux state
export const useLanguageAndSpeed = () => {
  const language = useSelector((state) => state.audio.language);
  const speed = useSelector((state) => state.audio.speed);
  return { language, speed };
};

export const useAudioRepeat = () => {
  const dispatch = useDispatch();
  const repeatState = useSelector((state) => state.audio.repeat);

  const setRepeat = (repeat) => {
    dispatch(setRepeatState(repeat));
  };

  return {repeatState, setRepeat};
};

export const useAudioLabels = () => {
  const dispatch = useDispatch();
  const labelsState = useSelector((state) => state.audio.labels);

  const appendLabelAction = (label) => {
    dispatch(appendLabel(label));
  };

  const removeLabelAction = (label) => {
    dispatch(removeLabel(label));
  };

  return {
      labelsState,
      appendLabel: appendLabelAction,
      removeLabel: removeLabelAction,
  };
};


export const useTimeKeepingApi = () => {
  const { lessonName, trackName, language, speedLevel, partName } = useParamsPlayer();

  const appendTimeAPI = async () => {
    const currentDate = new Date();

    // Get individual components of the date
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0');

    // Format the date as a string (you can customize the format as needed)
    const dateString = `${year}-${month}-${day}`;

    const params = {
      lesson: lessonName,
      track: trackName,
      lang: language,
      speed: speedLevel,
      date:dateString,
      part:partName 
    };
    
    const queryString = Object.keys(params)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
      .join('&');
    
    const urlWithParams = SheetEndpoint + '?' + queryString;
    
    try {
      const response = await fetch(urlWithParams)  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.text();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  
  }

  const appendTimeAction = () => {
    appendTimeAPI()
  }

  return {appendTime: appendTimeAction}
};