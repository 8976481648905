import { combineReducers } from 'redux';
import audioReducer from 'ducks/audioDuck';
import lessonsReducer from 'ducks/lessonsDuck';
import lessonDetailReducer from 'ducks/lessonDetailDuck';
import transcriptsReducer from 'ducks/transcriptsDuck';

const rootReducer = combineReducers({
  audio: audioReducer,
  lessons: lessonsReducer,
  lessonDetail: lessonDetailReducer,
  transcripts: transcriptsReducer,
  // Add other reducers here if needed
});

export default rootReducer;
