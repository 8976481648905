// lessonDetailUtils.js
// Utility functions and custom hooks for managing lesson details state

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLessonDetailRequest, fetchLessonDetailSuccess, fetchLessonDetailFailure, setSelectedLessonId } from 'ducks/lessonDetailDuck';
import { selectLessonDetail, selectLoading, selectError } from 'ducks/lessonDetailSelectors';

export const fetchLessonDetailApi = async (dispatch, lessonId) => {
  dispatch(fetchLessonDetailRequest());

  try {
    // Replace the API endpoint with your actual API endpoint and include the lessonId
    const response = await fetch(`/data/${lessonId}.json`)
    const data = await response.json();
    dispatch(fetchLessonDetailSuccess(data));
  } catch (error) {
    dispatch(fetchLessonDetailFailure(error.message));
  }
};

export const useLessonDetail = () => {
  const dispatch = useDispatch();
  const lessonDetail = useSelector(selectLessonDetail);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  const setLessonId = (lessonId) => {
    dispatch(setSelectedLessonId(lessonId));
  };

  const fetchLesson = (lessonId) => {
    fetchLessonDetailApi(dispatch, lessonId);
  };

//   useEffect(() => {
//     // Assuming you want to fetch the lesson details when the component mounts
//     fetchLesson(dispatch, lessonDetail.selectedLessonId);
//   }, [lessonDetail.selectedLessonId]);

  return { lessonDetail, loading, error, setLessonId, fetchLesson };
};
