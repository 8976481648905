import React, { useState , useRef, useEffect} from 'react';
import { useAudioUrl,useUpdateCurrentTime,useFocusItem ,useAudioRepeat, useTimeKeepingApi } from 'utils/audioUtils';
import debounce from 'lodash/debounce';


const AudioPlayer = () => {
    const {repeatState} = useAudioRepeat()
    const audioUrl = useAudioUrl();
    const updateCurrentTime = useUpdateCurrentTime()
    const focusItem = useFocusItem();    
    const audioRef = useRef();
    const [prevAudioUrl, setPrevAudioUrl] = useState(null);
    const [timerDuration] = useState(30000); // 30 seconds
    const {appendTime} = useTimeKeepingApi()
    let lastAppendTime = 0;
    useEffect(() => {
        // Check if the new value is different from the old one
        if (audioUrl && audioUrl !== prevAudioUrl) {
          setPrevAudioUrl(audioUrl);
        }
      
      }, [audioUrl]);
    
    // Handle time update in the audio player
    const handleTimeUpdate = (event) => {
        const currentTime = event.target.currentTime;
        const dur = audioRef.current.duration;
        if(focusItem.itemId && currentTime >= focusItem.end - 0.2){
            beginFocusFrame()
        }
        else if(repeatState && currentTime >= dur - 0.2){
            restartAudio()
        }
        else{
            updateCurrentTime(currentTime);
        } 
    };

    useEffect(() => {
        if (audioRef && audioRef.current) {
            audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
        }
    
        return () => {
            if (audioRef && audioRef.current) {
                audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, [audioRef, updateCurrentTime]);

    useEffect(()=>{
        const audioControl = document.getElementById("main-audio-player");
            if(audioControl&& audioUrl) {
                //Register the changed source
                audioControl.load();
            }

        }, 
    [audioUrl])

    const beginFocusFrame = () => {
        if (audioRef.current.paused) {
        audioRef.current.play();
        }
    
        audioRef.current.currentTime = focusItem.start;
        updateCurrentTime(focusItem.start);
    };
    
    
    const restartAudio = () => {
        if (audioRef.current.paused) {
        audioRef.current.play();
        }
    
        audioRef.current.currentTime = 0;
        updateCurrentTime(0);
    };

 
    


    useEffect(() => {
      const audioElement = audioRef.current;
      let timer;
  
      const checkAudioPlaying = () => {
        const currentTime = Date.now();
  
        // Check if it's been at least 30 seconds since the last appendTime call
        if (currentTime - lastAppendTime >= timerDuration) {
          appendTime();
          lastAppendTime = currentTime;
        }
      };
  
      const handlePlay = () => {
        // Start the continuous check interval when audio starts playing
        timer = setInterval(checkAudioPlaying, timerDuration);
      };
  
      const handlePause = () => {
        // Clear the interval when audio is paused or stopped
        clearInterval(timer);
      };
  
      if (audioElement) {
        audioElement.addEventListener('play', handlePlay);
        audioElement.addEventListener('pause', handlePause);
      }
  
      return () => {
        if (audioElement) {
          audioElement.removeEventListener('play', handlePlay);
          audioElement.removeEventListener('pause', handlePause);
        }
  
        // Clear the interval when the component is unmounted
        clearInterval(timer);
      };
    }, [audioUrl]);


    return (
        <audio ref={audioRef} controls style={{ width: '100%' }} id={`main-audio-player`}>
            <source src={audioUrl} type="audio/mp3" />
            Your browser does not support the audio element.
        </audio>
    );
};

export default AudioPlayer;
