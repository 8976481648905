// audioDuck.js
// Redux Duck for managing audio player state

// Action types
const SET_CURRENT_TIME = 'audio/SET_CURRENT_TIME';
const SET_AUDIO_URL = 'audio/SET_AUDIO_URL';
const SET_FOCUS = 'audio/SET_FOCUS';
const SET_LANGUAGE = 'audio/SET_LANGUAGE'; // New action type for setting language
const SET_SPEED = 'audio/SET_SPEED'; // New action type for setting speed
const SET_REPEAT = 'audio/SET_REPEAT'; // New action type for setting repeat state
const APPEND_LABEL = 'audio/APPEND_LABEL';
const REMOVE_LABEL = 'audio/REMOVE_LABEL';


// Valid ranges for language and speed
const VALID_LANGUAGES = ['chinese', 'spanish', 'germany', 'japanese', 'dutch']; // Example valid language codes
const VALID_SPEEDS = ['very_slow', 'slow', 'normal']; // example valid speed values
export const VALID_LABELS = {
  chinese: ['en', 'py'],
  spanish: ['en'],
  germany: ['en'],
  japanese: ['en','romaji'],
  dutch: ['en'],
} // New array of valid label values

 

// Initial state
const initialState = {
  currentTime: 0,
  audioUrl: null,
  focusItem: {},
  language: 'chinese', // New state property for language with default value
  speed: 'very_slow', // New state property for speed with default value
  repeat: true, // New state property for repeat with default value
  labels: [],

};

// Action creators
export const setCurrentTime = (time) => ({
  type: SET_CURRENT_TIME,
  payload: time,
});

export const setAudioUrl = (url) => ({
  type: SET_AUDIO_URL,
  payload: url,
});

export const setRepeatState = (repeat) => ({
  type: SET_REPEAT,
  payload: repeat,
});

export const appendLabel = (labels) => {
  const validLabels = Array.isArray(labels)
    ? labels
    : [labels];

  return {
    type: APPEND_LABEL,
    payload: validLabels,
  };
};

export const removeLabel = (label) => ({
  type: REMOVE_LABEL,
  payload: label,
});

export const setFocus = (itemId, start, end) => {
    // Validate input parameters
    if (itemId === undefined || start === undefined || end === undefined) {
      console.error('Invalid input parameters for setFocus action.');
      return { type: 'INVALID_ACTION' }; // or throw an error, depending on your error handling strategy
    }
  
    return {
      type: SET_FOCUS,
      payload: { itemId, start, end },
    };
};


export const setLanguage = (language) => {
    // Validate language against the valid range
    
    if (VALID_LANGUAGES.includes(language)) {
      return {
        type: SET_LANGUAGE,
        payload: language,
      };
    } else {
      console.error('Invalid language value.');
      return { type: 'INVALID_ACTION' }; // or throw an error
    }
};
  
export const setSpeed = (speed) => {
    // Validate speed against the valid range
    
    if (VALID_SPEEDS.includes(speed)) {
      return {
        type: SET_SPEED,
        payload: speed,
      };
    } else {
      console.error('Invalid speed value.');
      return { type: 'INVALID_ACTION' }; // or throw an error
    }
};

// Reducer
const audioReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_TIME:
      return { ...state, currentTime: action.payload };

    case SET_AUDIO_URL:
      return { ...state, audioUrl: action.payload };

    case SET_FOCUS:
        const { itemId, start, end } = action.payload;
        return {
          ...state,
          focusItem: { itemId, start, end },
        };
        
    case SET_LANGUAGE:
      
      return { ...state, language: action.payload };

    case SET_SPEED:
      
      return { ...state, speed: action.payload };
    
    case SET_REPEAT:
      return { ...state, repeat: action.payload };

    case APPEND_LABEL:
      return {
        ...state,
        labels: [...state.labels, ...action.payload],
      };
  
    case REMOVE_LABEL:
      return {
        ...state,
        labels: state.labels.filter((label) => label !== action.payload),
      };

    default:
      return state;
  }
};

export default audioReducer;
