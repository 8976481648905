// Routes.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LessonList from 'LessonList';
import AudioPlaylist from 'AudioPlaylist';
import AudioTranscriptPlayer from 'AudioTranscriptPlayer';
import LessonDetail from 'LessonDetail';

const AppRoutes = () => {
  return (
    <Router>
     <Routes>
       <Route path="/" element={<LessonList />} />
       <Route path="/lessons"  element={<LessonList />} />
       <Route path="/lessons/:lessonName"  element={<LessonDetail />} />
       {/* <Route path="/audio/:id" element={<AudioPlayer />} /> */}
       <Route path="/play/:lessonNameParam/:trackNameParam/:languageParam/:speedLevelParam/:partNameParam" element={<AudioTranscriptPlayer />} />
     </Routes>
   </Router>

  );
};

export default AppRoutes;
