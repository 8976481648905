// lessonDetailDuck.js
// Redux Duck for managing lesson details state

// Action types
const SET_SELECTED_LESSON_ID = 'lessonDetail/SET_SELECTED_LESSON_ID';
const FETCH_LESSON_DETAIL_REQUEST = 'lessonDetail/FETCH_LESSON_DETAIL_REQUEST';
const FETCH_LESSON_DETAIL_SUCCESS = 'lessonDetail/FETCH_LESSON_DETAIL_SUCCESS';
const FETCH_LESSON_DETAIL_FAILURE = 'lessonDetail/FETCH_LESSON_DETAIL_FAILURE';

// Initial state
const initialState = {
  selectedLessonId: null,
  lessonDetail: {},
  loading: false,
  error: null,
};

// Action creators
export const setSelectedLessonId = (lessonId) => ({
  type: SET_SELECTED_LESSON_ID,
  payload: lessonId,
});

export const fetchLessonDetailRequest = () => ({
  type: FETCH_LESSON_DETAIL_REQUEST,
});

export const fetchLessonDetailSuccess = (lessonDetail) => ({
  type: FETCH_LESSON_DETAIL_SUCCESS,
  payload: lessonDetail,
});

export const fetchLessonDetailFailure = (error) => ({
  type: FETCH_LESSON_DETAIL_FAILURE,
  payload: error,
});

// Reducer
const lessonDetailReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_SELECTED_LESSON_ID:
        return { ...state, selectedLessonId: action.payload };
  
      case FETCH_LESSON_DETAIL_REQUEST:
        return { ...state, loading: true, error: null };
  
      case FETCH_LESSON_DETAIL_SUCCESS:
        return { ...state, lessonDetail: action.payload, loading: false, error: null };
  
      case FETCH_LESSON_DETAIL_FAILURE:
        return { ...state, loading: false, error: action.payload };
  
      default:
        return state;
    }
};
  
export default lessonDetailReducer;