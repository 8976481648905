import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTranscriptRequest, fetchTranscriptSuccess, fetchTranscriptFailure, fetchTranscript } from 'ducks/transcriptsDuck';


export const useTranscripts = () => {
    const dispatch = useDispatch();
    const transcripts = useSelector((state) => state.transcripts.transcripts);
    const loading = useSelector((state) => state.transcripts.loading);
    const error = useSelector((state) => state.transcripts.error);
    const fetchTranscriptData = (transcriptUrl) => {
      dispatch(fetchTranscript(dispatch, transcriptUrl));
    };
  
    return { transcripts, loading, error, fetchTranscriptData };
  };
  